import Actions from "./Actions";
import StringTable, { DefaultLang, SupportLangs } from "../StringTable";

const Langs = SupportLangs.map(s => s.key);

function reducer(state = DefaultLang, { type, payload }) {
  switch (type) {
    case Actions.SET_LANG:
      if (Langs.indexOf(payload) > -1) {
        return payload;
      }
      return state;

    default:
      return state;
  }
}

let lang = null;

export default function cachedReducer(state, action) {
  state = reducer(state, action);
  lang = state;
  return state;
}

export function getLang(state) {
  return state;
}

export function s(key) {
  return StringTable[lang][key];
}

export function d(data, key) {
  return data[`${key}_${lang}`];
}
