import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { navigate } from "gatsby";
import { CloseOutlined } from "@ant-design/icons";
import { Layout, Menu, Input, Modal, Form } from "antd";
import { USER_TYPE } from "./constants";
import _ from "lodash";
import "./index.css";
import { RoleID } from "./Model";
import ActionCreator from "./ActionCreator";
import Selectors from "./Selectors";
import Navbar from "./Components/Navbar";
import Delighters from "./Components/ReactDelighters";
import * as Survey from "./Contexts/SurveyContext";

import iconGroup from "./images/group.svg";
import iconAdd from "./images/ButtonsTertiary.svg";
import iconFilter from "./images/filter_list_black.svg";

import FolderIcon from "./svg-icons/folder.svg";
import MySurveyIcon from "./svg-icons/mysurvey.svg";
import SharedIcon from "./svg-icons/sharewithme.svg";

const queryString = require("query-string");

const navbarHeight = 64;
const siderMinWidth = 240;
const siderMaxWidth = 320;

let windowGlobal = typeof window !== "undefined" && window;

class MainLayout extends Component {
  state = {
    teamName: "",
    teams: [],
    menuQueryStatue: false,
    searchName: "",
    menuSearchMode: false,
    showCreateTeamModal: false,
    selectMenu: 0,
    siderWidth: siderMinWidth,
  };

  componentDidMount() {
    const category = queryString.parse(this.props.location.search).category;
    if (category === "my") {
      this.setState({ selectMenu: 1 });
    } else if (category === "shared") {
      this.setState({ selectMenu: 2 });
    } else {
      this.setState({ selectMenu: 0 });
    }

    window.addEventListener("scroll", this._onScroll);
    this.siderWidthSetup();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  createTeam(name) {
    if (name.length > 50) {
      alert("團隊名稱最大長度為50");
      return;
    }

    let { profile } = this.props;
    try {
      let data = {
        teamId: "",
        userCode: profile.UserCode,
        name: name,
      };
      Survey.Actions.submitTeam(data).then(res => {
        if (res.code && res.code === 200) {
          navigate("/team?id=" + res.data + "&t=1");
          Survey.Actions.fetchMyTeamList(profile.UserCode).then(res => {
            this.setState({ teams: res });
            this.setState({ menuQueryStatue: true });
          });
        } else {
          alert(res.message);
        }
      });
    } catch (err) {}
  }

  siderWidthSetup() {
    const widthCalculate = w => {
      const width = (siderMinWidth / 1440) * w;
      return width < siderMinWidth
        ? siderMinWidth
        : width > siderMaxWidth
        ? siderMaxWidth
        : width;
    };

    window.addEventListener("resize", e => {
      this.setState({ siderWidth: widthCalculate(e.target.innerWidth) });
    });
    this.setState({ siderWidth: widthCalculate(window.innerWidth) });
  }

  render() {
    const { menuSearchMode, showCreateTeamModal } = this.state;
    let {
      location: { pathname },
      profile,
    } = this.props;
    /**
     * CDP版面不顯示Navbar
     * 檢查profile的type
     */
    const accountType = _.get(profile, "Type");
    console.debug("MainLayout:accountType", accountType);
    const hideNavbar = accountType === USER_TYPE.CDP;

    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = true;
    let withSideMenu = false;

    if (pathname === "/survey/") {
      withPaddingTop = false;
    } else if (!profile && pathname === "/") {
      // show navbar when user in login page
      withPaddingTop = true;
    } else if (!profile) {
      // for anonymous, we do not show navbar
      withPaddingTop = false;
    }
    /**
     * CDP版面不顯示SideMenu
     */
    if (
      accountType !== USER_TYPE.CDP &&
      profile &&
      (pathname === "/" || pathname === "/team/")
    ) {
      withSideMenu = true;

      if (this.state.menuQueryStatue === false) {
        const roleID = _.get(profile, "RoleId", RoleID.SystemContractUser);
        if (roleID === RoleID.SystemAdmin) {
          Survey.Actions.fetchTeamList(profile.UserCode).then(res => {
            this.setState({ teams: res });
            this.setState({ menuQueryStatue: true });
          });
        } else {
          Survey.Actions.fetchMyTeamList(profile.UserCode).then(res => {
            this.setState({ teams: res });
            this.setState({ menuQueryStatue: true });
          });
        }
      }
    }

    return (
      <Delighters>
        <Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>中華電信CRM問卷平台</title>
            <meta
              name="description"
              content={`Syscom Survey Web | Survey | Questionaire | 中華電信 | 問卷平台`}
            />
            <meta name="version" content={`${global.BUILD_VERSION}`} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, user-scaleable=0"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" href="/favicon-72x72.png" type="image/png"></link>
          </Helmet>
          {!hideNavbar && ( // 根據profile中的type的值決定是否顯示Navbar
            <Navbar
              display={withPaddingTop}
              passBreakpoint={passBreakpoint || withPaddingTop}
              pathname={pathname}
            />
          )}
          <Layout style={{ backgroundColor: "#f9f9fd" }}>
            {withSideMenu && (
              <Layout.Sider
                theme="light"
                width={this.state.siderWidth}
                style={{ backgroundColor: "#fafafa", height: "100vh" }}
              >
                <MainMenuWrapper>
                  <MainBtn
                    isSelected={this.state.selectMenu === 0}
                    onClick={() => {
                      this.setState({ selectMenu: 0 });
                      console.debug("navigate to root");
                      navigate("/");
                    }}
                  >
                    <div className="pic">
                      <FolderIcon />
                    </div>
                    <div className="title">所有問卷</div>
                  </MainBtn>
                  <MainBtn
                    isSelected={this.state.selectMenu === 1}
                    onClick={() => {
                      this.setState({ selectMenu: 1 });
                      navigate("/?category=my");
                    }}
                  >
                    <div className="pic">
                      <MySurveyIcon />
                    </div>
                    <div className="title">我的問卷</div>
                  </MainBtn>
                  <MainBtn
                    isSelected={this.state.selectMenu === 2}
                    onClick={() => {
                      this.setState({ selectMenu: 2 });
                      navigate("/?category=shared");
                    }}
                  >
                    <div className="pic">
                      <SharedIcon />
                    </div>
                    <div className="title">與我共享的問卷</div>
                  </MainBtn>
                </MainMenuWrapper>
                <Menu className="sub-menu-wrapper" mode="vertical">
                  <Menu.Divider />
                  <div style={{ padding: 0, margin: "12px 0 0 0" }}>
                    {menuSearchMode ? (
                      <Input
                        className="team-search"
                        placeholder="搜尋團隊名稱..."
                        onChange={e => {
                          this.setState({ searchName: e.target.value });
                        }}
                        bordered={false}
                        suffix={
                          <CloseOutlined
                            size={32}
                            onClick={() => {
                              this.setState({ menuSearchMode: false });
                              this.setState({ searchName: "" });
                            }}
                          />
                        }
                      />
                    ) : (
                      <div className="sub-menu-title-wrapper">
                        <img src={iconGroup} width="20" />
                        <span
                          style={{
                            color: "rgba(15, 14, 35, 0.8)",
                            paddingLeft: "8px",
                            fontSize: 16,
                          }}
                        >
                          團隊
                        </span>
                        <img
                          src={iconAdd}
                          width="20"
                          style={{
                            marginLeft: "auto",
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({ showCreateTeamModal: true });
                          }}
                        />
                        <img
                          src={iconFilter}
                          width="20"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({ menuSearchMode: true });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <Menu className="team-list-wrapper" mode="vertical">
                    {this.state.teams?.map((item, idx) => {
                      if (item.name.includes(this.state.searchName)) {
                        return (
                          <Menu.Item
                            key={idx}
                            onClick={() => {
                              navigate(`/team?id=` + item.teamid);
                            }}
                          >
                            {item.name}
                          </Menu.Item>
                        );
                      }
                    })}
                  </Menu>
                </Menu>
              </Layout.Sider>
            )}
            <Layout.Content style={{ overflowX: "scroll" }}>
              <MainContent withPaddingTop={withPaddingTop}>
                {this.props.children}
              </MainContent>
            </Layout.Content>
          </Layout>
          <Modal
            className="custom-modal"
            title={"新增團隊"}
            width={480}
            visible={showCreateTeamModal}
            onCancel={async () => {
              this.setState({ showCreateTeamModal: false });
              this.setState({ teamName: "" });
            }}
            okText={"確定"}
            onOk={async () => {
              this.createTeam(this.state.teamName);
              this.setState({ showCreateTeamModal: false });
              this.setState({ teamName: "" });
            }}
            cancelText={"取消"}
          >
            <div>
              <Form layout="vertical">
                <div>
                  <Input
                    placeholder="輸入團隊名稱"
                    name="name"
                    value={this.state.teamName}
                    onChange={e => {
                      this.setState({ teamName: e.target.value });
                    }}
                  />
                </div>
              </Form>
            </div>
          </Modal>
          {/* <Footer pathname={pathname} /> */}
        </Wrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: #f9f9fd;
  display: flex;
  flex-direction: column;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .sub-menu-wrapper {
    background-color: #fafafa;
    border: none;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .sub-menu-title-wrapper {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 21px 0 32px;
  }

  .team-list-wrapper {
    height: 100px;
    flex-grow: 1;
    /* overflow-y: scroll; */
    overflow: hidden auto;
    background-color: #fafafa;
    border: none;
    border-color: transparent;

    .ant-menu-item {
      color: rgba(15, 14, 35, 0.8);
      height: 36px;
      margin: 0;
      padding: 0 20px 0 32px;

      &:hover {
        color: #837ae8;
      }
    }
  }

  .team-search {
    background-color: #fafafa;
    border: none;
    box-shadow: none;
    border-color: transparent;
    align-items: center;
    height: 48px;
    padding-left: 32px;
    border-bottom: 1px solid #837ae8;

    .ant-input {
      height: 24px;
    }
  }
`;

const MainContent = styled.div`
  padding-top: ${props => (props.withPaddingTop ? navbarHeight : 0)}px;
  height: 100vh;
`;

const MainMenuWrapper = styled.div`
  padding: 36px 0 20px;
  margin-top: ${navbarHeight}px;
`;

const MainBtn = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 16px 12px 32px;
  cursor: pointer;
  color: ${props => (props.isSelected ? "#534ab3" : "rgba(15, 14, 35, 0.8)")};

  .pic {
    display: inline-block;
    color: ${props => (props.isSelected ? "#534ab3" : "rgba(15, 14, 35, 0.8)")};
    width: 20px;
    height: 20px;
    margin-right: 7px;

    svg {
      width: 20px;
      height: 20px;

      path,
      polygon {
        fill: currentColor;
      }
    }
  }

  &:hover {
    color: #837ae8;

    .pic {
      color: #837ae8;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(MainLayout);
