export default function(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function byId(state, id) {
  return state.map[id];
}

export function list(state) {
  return state.list;
}
