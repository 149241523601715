import Actions from "./Actions";
const initialState = {
  ExtendColorPalette: [],
  SurveyId: "",
};

export default function reducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case Actions.SET_THEME:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
