export const SupportLangs = [
  { display: "繁體中文", key: "tw" },
  { display: "English", key: "en" },
];

export const DefaultLang = "tw";

const StringTable = {
  tw: {
    welcome: "歡迎!",
  },
  en: {
    welcome: "Welcome!",
  },
};

export default StringTable;
