import Actions from "./Actions";

const initialState = null;

export default function localCart(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.SET_LOCAL_CART_DATA:
      return payload;

    case Actions.SET_LOCAL_CART_CONFIG_DATA:
      return { ...state, config: { ...payload } };

    default:
      return state;
  }
}

export function getLocalCartData(state) {
  return state || {};
}

export function getLocalCartDataConfig(state) {
  return (state && state.config) || {};
}
