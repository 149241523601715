import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import { Link, navigate } from "gatsby";

import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Icon from "./Icon";
import { FontSize } from "./Widget";
import { ProfileOutlined } from "@ant-design/icons";
import _ from "lodash";

const background = "#423a8f";
const altBackground = "#e6e6e6";
const textColor = "#4E4E4E";
const textShadow = "2px 2px #ccc";
const breakpoint = 800;

export const navbarHeight = 64;

// const navItems = [
//   { children: "我的調查問卷", url: "/", disabled: false },
//   { children: "問卷範本", url: "/templates/", disabled: false },
//   { children: "個資聲明內文版本管理", url: "/privacy/", disabled: false },
// ];

class Navbar extends React.Component {
  state = {
    openMenu: false,
  };

  render() {
    let { passBreakpoint, display = true, pathname, profile } = this.props;
    if (!display) {
      return null;
    }

    return (
      <Style.Wrapper passBreakpoint={passBreakpoint}>
        <Style.Content>
          <div className="logo">
            {profile ? (
              <Link to="/">
                <img src="/images/Survey_Logo_White_v2.svg" alt="" />
              </Link>
            ) : (
              <img src="/images/Survey_Logo_White_v2.svg" alt="" />
            )}
          </div>
          {this._renderNavBarContent()}
        </Style.Content>
      </Style.Wrapper>
    );
  }
  _renderNavBarContent() {
    let { profile, pathname, appActions } = this.props;

    var navItems =
      profile && profile.RoleId === 1
        ? [
            { children: "我的調查問卷", url: "/", disabled: false },
            { children: "問卷範本", url: "/templates/", disabled: false },
            {
              children: "個資聲明內文版本管理",
              url: "/privacy/",
              disabled: false,
            },
          ]
        : [
            { children: "我的調查問卷", url: "/", disabled: false },
            { children: "問卷範本", url: "/templates/", disabled: false },
          ];

    return (
      <Style.LargeScreenWrapper breakpoint={breakpoint}>
        <div className="items-wrapper">
          {profile &&
            navItems &&
            navItems.map(({ children, url, disabled }, idx) => (
              <Link
                disabled={disabled}
                to={url}
                key={idx}
                className={`nav-item${url === pathname ? " active" : ""}${
                  disabled ? " disabled" : ""
                }`}
              >
                {children}
              </Link>
            ))}
        </div>

        {profile && (
          <div className="items-wrapper left">
            <div
              className="help-icon"
              onClick={() => {
                navigate("/help");
              }}
            >
              <Icon.QuestionCircleFilled
                size={32}
                style={{ color: "#fff", fontSize: 32 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                fontSize: `${FontSize.head}px`,
              }}
            >
              你好 {profile?.userName || ""}
              <Ant.Popover
                placement="bottomRight"
                content={
                  <div
                    style={{ minWidth: 122, marginLeft: -16, marginRight: -16 }}
                  >
                    {profile?.RoleId === 1 && (
                      <div
                        className="hover-mask"
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 16,
                          paddingRight: 16,
                        }}
                        onClick={() => {
                          navigate("/users");
                        }}
                      >
                        帳號列表
                      </div>
                    )}
                    {profile?.RoleId === 1 && (
                      <div
                        className="hover-mask"
                        style={{
                          paddingTop: 4,
                          paddingBottom: 4,
                          paddingLeft: 16,
                          paddingRight: 16,
                        }}
                        onClick={() => {
                          navigate("/teams");
                        }}
                      >
                        團隊列表
                      </div>
                    )}
                    <div
                      className="hover-mask"
                      style={{
                        paddingTop: 4,
                        paddingBottom: 4,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                      onClick={() => {
                        appActions.logout();
                      }}
                    >
                      登出
                    </div>
                  </div>
                }
                trigger="hover"
              >
                <div className="account-icon" style={{ marginLeft: 12 }}>
                  <Icon.AccountCircle color={"#fff"} size={48} />
                </div>
              </Ant.Popover>
            </div>
          </div>
        )}
      </Style.LargeScreenWrapper>
    );
  }
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 0px;
    height: ${navbarHeight}px;
    z-index: 10;
    border-bottom: 0.5px solid #bdbdbd;
    background-color: #423a8f;
  `,

  Content: styled.div`
    margin: 0 auto;
    padding-left: 0;
    padding-right: 34px;
    height: 100%;
    display: flex;
    align-items: center;

    & > .logo {
      width: 172px;
      height: 64px;
      color: #fff;
      font-size: ${FontSize.subTitle}px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        margin-left: 34px;
        width: 96px;
        height: auto;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 30px;
        padding-right: 46px;
        width: 172px;
        height: 100%;
        img {
          margin: auto;
          width: 100%;
          height: auto;
        }
      }
    }
  `,

  LargeScreenWrapper: styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .items-wrapper {
      display: flex;
      align-items: center;

      & > .nav-item {
        font-size: ${FontSize.head}px;
        color: #8b87bc;
        margin-right: 40px;
        padding: 0 5px;
      }

      & > .nav-item.disabled {
        pointer-events: none;
      }

      & > .nav-item.active {
        color: #fff;
        position: relative;

        &:after {
          position: absolute;
          content: "";
          display: block;
          width: calc(100% - 38px);
          height: 3px;
          background-color: #b5f5ec;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    & .items-wrapper.left {
      display: flex;
      align-items: center;

      & .help-icon {
        margin-right: 22px;
        cursor: pointer;
      }

      & .account-icon {
      }
    }
  `,
};

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Navbar);
