import React from "react";
import { connect, Provider } from "react-redux";
import { getStore } from "./Store";
import { navigate } from "gatsby";
import MainLayout from "./MainLayout";
import Selectors from "./Selectors";
import ActionCreator from "./ActionCreator";
import * as Survey from "./Contexts/SurveyContext";
import "antd/dist/antd.less";
import { bindActionCreators } from "redux";
import * as Ant from "antd"; //wiki加入IE判斷
import { Color } from "./Components/Widget"; //wiki加入IE判斷
import { ExclamationCircleFilled } from "@ant-design/icons"; //wiki加入IE判斷

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    let { error } = this.state;

    if (error) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: 20, backgroundColor: "grey" }}>
            <h2 style={{ color: "white" }}>Oops! We have errors!</h2>
            <p style={{ color: "#ddd" }}>Please reload the page</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
/**
 * code-review
 * 頁面封裝
 */
class Page extends React.Component {
  async componentDidMount() {
    let { appActions, location, profile } = this.props;

    // TODO: we will deprecate the Service module, so ga service needs to be re-written
    // appActions.ga.trackingPageView(location.pathname + location.search);
    // if (!getStore().getState().user.data) {
    //   await appActions.autoLogin();
    // }

    // TODO: if not login, redirect to landingPage, else: show logining spinner and then render children after auto login done
    // 自動登入
    // if (!profile) {
    //   try {
    //     await appActions.autoLogin();
    //   } catch (err) {}
    // }

    let windowGlobal = typeof window !== "undefined" && window;
    let token = windowGlobal.localStorage.getItem("token");
    if (!token) {
      console.log("appActions", appActions);
      appActions.logout();
    }

    //wiki加入IE判斷
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      _confirmIE();
      return;
    }
    // 如果目前登入頁面是SSO，不要自動登入
    if (location.pathname === "/auth") {
      return;
    }

    if (window.localStorage.getItem("token")) {
      Survey.Actions.setInternalLocalStorageToken(true);
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <MainLayout key={this.props.lang} {...this.props}>
          {this.props.children}
        </MainLayout>
      </ErrorBoundary>
    );
  }
}

//wiki加入IE判斷
function _confirmIE() {
  Ant.Modal.warning({
    title: "本問卷不支援 IE 瀏覽器",
    content: "為提供最佳操作體驗，請改用 Chrome 瀏覽器。",
    okText: "關閉",
    onOk: () => {
      window.opener = null;
      window.open("", "_self");
      window.close();
    },
    icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
  });
}

Page = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Page);
/**
 * code review
 * 頁面封裝function，主要提供component與初始化redux store
 * 並且提供context給component使用
 * @param {} Comp
 * @returns
 */
export function withPage(Comp) {
  class PageWrapper extends React.Component {
    componentDidMount() {
      let { pageContext } = this.props;
      // create redux store
      getStore(pageContext.initialState);

      let windowGlobal = typeof window !== "undefined" && window;
      let token = windowGlobal.localStorage.getItem("token");
      if (!token) {
        /**
         * code-review: 太分散，多處都有回到首頁的程式，由於CDP SSO不希望使用者看到登入頁面，統一使用logout處理。
         * 將此段到上面的Page component處理，並且統一使用appActions.logout()來處理登出
         * windowGlobal.localStorage.removeItem("token");
         * windowGlobal.localStorage.removeItem("profile");
         * navigate("/");
         */
      }
    }

    render() {
      return (
        <Provider store={getStore()}>
          <Survey.Provider>
            <Page {...this.props}>
              <Comp {...this.props}>{this.props.children}</Comp>
            </Page>
          </Survey.Provider>
        </Provider>
      );
    }
  }

  PageWrapper.displayName = "Page-" + Comp.displayName;
  return PageWrapper;
}
