export default function(query) {
  if (!query) {
    return {};
  }

  if (query[0] === "?") {
    query = query.substr(1, query.length);
  }

  let result = {};
  for (let pair of query.split("&")) {
    if (pair) {
      let [key, value] = pair.split("=");
      result[key] = value;
    }
  }

  return result;
}
